import React from 'react';
import { useTranslation } from 'react-i18next';
import { Rss } from 'lucide-react';

const TopicCard = ({ item, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="topic-card" onClick={() => onClick(item)}>
      <div className="topic-card-content">
        <div className="topic-card-thumbnail">
          {item.news_image_url ? (
            <img src={item.news_image_url} alt={item.title} />
          ) : (
            // <img src='https://poppin.link/images/other/cover-noimage.png' alt={t('noImageAvailable')}/>
            <Rss size={20} />
          )}
        </div>
        <div className="topic-card-text">
          <p className="topic-card-title">{item.title}</p>
          {item.release_date && (
            <p className="topic-card-date">{item.release_date}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopicCard;