import React, { useState, useEffect, useRef } from 'react';
import { X, SquareArrowOutUpRight, Rss } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const FullScreenNewsCard = ({ newsData, onClose, selectedIndex = 0, handleNavigation }) => {
  const [isVisible, setIsVisible] = useState(false);
  const newsItemRefs = useRef([]);
  const location = useLocation();
  const { t } = useTranslation();

  const handleTagClick = (tag, tagType) => {
    const paramKey = tagType === 'location' ? 'loc' : 'tag';
    const path = `${location.pathname}?${paramKey}=${encodeURIComponent(tag)}`;
    handleNavigation(path);
    onClose();
  };

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

  const renderTags = (tag, tagType) => {
    if (!tag) return null;
    const isLocationTag = tagType === 'location';
    const truncatedTag = truncateString(tag, 20);
    return (
      <span
        onClick={() => handleTagClick(tag, tagType)}
        className={`tag-link ${isLocationTag ? 'location-tag' : 'normal-tag'}`}
        title={tag}
      >
        {truncatedTag}
      </span>
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 50);
    document.body.style.overflow = 'hidden';
    
    return () => {
      clearTimeout(timer);
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    if (isVisible && newsItemRefs.current[selectedIndex]) {
      newsItemRefs.current[selectedIndex].scrollIntoView({ behavior: 'smooth' });
      newsItemRefs.current[selectedIndex].classList.add('highlight');
      setTimeout(() => {
        newsItemRefs.current[selectedIndex].classList.remove('highlight');
      }, 1000);
    }
  }, [isVisible, selectedIndex]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClose, 300);
  };

  const handleBackgroundClick = (e) => {
    if (e.target.classList.contains('fullscreen-card')) {
      handleClose();
    }
  };

  return (
    <div 
      className={`fullscreen-card ${isVisible ? 'visible' : ''}`}
      onClick={handleBackgroundClick}
    >
      <div className="fullscreen-card-content" onClick={(e) => e.stopPropagation()}>
        <div className="fullscreen-card-header">
          <h2>{newsData.header_title}</h2>
          <button onClick={handleClose} className="close-news-button">
            <X size={24} />
          </button>
        </div>
        <div className="fullscreen-card-body">
          {newsData.news_items.map((item, index) => (
            <div key={index} className="news-item" ref={el => newsItemRefs.current[index] = el}>
              <div className="news-item-header">
                <div className="news-item-thumbnail">
                  {item.news_image_url ? (
                    <img src={item.news_image_url} alt={item.title} />
                  ) : (
                    <Rss size={20} />
                  )}
                </div>
                <h3 className='news-title'>{item.title}</h3>
              </div>
              <span className="article-search">
                <a 
                  href={`https://www.google.com/search?q=${encodeURIComponent(item.title)}`} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  <SquareArrowOutUpRight size={10} />
                </a>
              </span>
              <p className="article">{item.article}</p>
              <div className="news-item-footer">
                <div className="news-item-tags-container">
                  {renderTags(item.tag, item.tag_type)}
                </div>
                <div className="news-item-date-container">
                  <p className="release-date">{item.release_date}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FullScreenNewsCard;